<template>
  <div v-if="isPermission && isEdit">
    <CCard>
      <CCardBody class="m-4">
        <CRow>
          <CCol md="10">
            <h2 class="font-weight-normal text-left text-dark">
              {{ $t('addMenber') }}
            </h2>
          </CCol>
          <CCol md="2" class="text-right align-text-center">
            <a id="createMemberBackLink" @click="$router.go(-1)">
              <CButton id="createMemberBackButton">
                <span><i class="fi fi-rr-arrow-left mr-2" style="position: relative; top: 2px;"></i></span>{{ $t('back') }}
              </CButton>
            </a>
          </CCol>
        </CRow>
        <hr />
        <p id="createMemberValidationAlert" class="text-danger font-weight-bold text-left">
          {{ validateAlert }}
        </p>
        <p id="createMemberErrors" class="text-danger font-weight-bold text-left">
          {{ errors }}
        </p>
        <CRow class="mt-4">
          <CCol>
            <div class="form-group">
              <label id="createMemberFirstnameLabel" class="h6 text-dark">
                {{ $t('firstname') }} <span class="text-danger">*</span>
              </label>
              <input
                id="createMemberFirstnameInput"
                v-model="firstname"
                type="text"
                class="form-control"
                :placeholder="$t('firstname')"
              />
            </div>
          </CCol>
          <CCol>
            <div class="form-group">
              <label id="createMemberLastnameLabel" class="h6 text-dark">
                {{ $t('lastname') }} <span class="text-danger">*</span>
              </label>
              <input 
                id="createMemberLastnameInput"
                v-model="lastname"
                type="text"
                class="form-control"
                :placeholder="$t('lastname')"
              />
            </div>
          </CCol>
        </CRow>
        <CRow class="mt-3">
          <CCol md="6">
            <div class="form-group">
              <label id="createMemberSexLabel" class="h6 text-dark">
                {{ $t('sex') }} <span class="text-danger">*</span>
              </label>
              <select id="createMemberSexSelect" v-model="sex" class="custom-select">
                <option
                :value="option.id"
                v-for="option in sexoptions"
                :key="option.id"
                >{{ $t(option.name) }}
                </option>
              </select>
            </div>
          </CCol>
          <CCol md="6">
            <div class="form-group">
              <label id="createMemberTelLabel" class="h6 text-dark">
                {{ $t('tel') }} <span class="text-danger">*</span>
              </label>
              <input
                id="createMemberTelInput"
                v-model="phone"
                type="text"
                class="form-control"
                :placeholder="$t('tel')"
              />
            </div>
          </CCol>
        </CRow>
        <label id="createMemberBirthDateLabel" class="h6 text-dark mt-3">
                {{ $t('dayMYBirth') }} 
              </label>
              <div id="createMemberBirthDatePicker" class="form-group datepicker-container">
               <VueDatePicker color="#29B46B" :placeholder="$t('chooseBirthDate')" :locale="getDateLocale()"
              :visible-years-number="90" v-model="dateOfBirth" format="DD-MM-YYYY" />
</div>

        <CButton id="createMemberSaveButton" class="mt-3 mb-3" color="success" block @click="validate()">
          {{ $t('saveMember') }}
        </CButton>
      </CCardBody>
    </CCard>
    <CRow>
      <CModal
        id="createMemberModal"
        :show.sync="popupModal"
        :footer="footer"
        centered
        :title="$t('saveMember')"
        color="success"
      >
        <h5 id="createMemberModalText" class="text-center">
          <b>{{ $t('confirmSaveMember') }} ?</b>
        </h5>
        <template #footer>
          <CRow class="justify-content-center col-md-12">
            <CCol col="4">
              <CButton
                id="createMemberConfirmButton" 
                color="success"
                block
                @click="saveMember()"
                v-if="loadingButton === false"
              >
                {{ $t('confirm') }}
              </CButton>
              <CButton
                id="createMemberLoadingButton"
                color="success"
                block
                v-else
                disabled
              >
                <CSpinner color="white" size="sm" />
              </CButton>
            </CCol>
            <CCol col="2"></CCol>
            <CCol col="4">
              <CButton id="createMemberCancelButton" color="light" @click="popupModal = false" block>
                {{ $t('cancel') }}
              </CButton>
            </CCol>
          </CRow>
        </template>
      </CModal>
    </CRow>
  </div>
  <div v-else id="createMemberNoPermission">
    <access-data></access-data>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import pos from '@/services/local'
import permis from '@/util/permission'

export default {
  components : {

  },
  data() {
    return {
      langLocale: '',
      calendarRender:0,
      firstname: null,
      lastname: null,
      sex: null,
      phone: null,
      dateOfBirth: null,
      popupModal: false,
      footer: '',
      validateAlert: '',
      showtime: 3,
      loadingButton: false,
      successAlert: '',
      errors: '',
      sexoptions: [
        {
          id: 1,
          name: 'male',
        },
        {
          id: 2,
          name: 'female',
        },
        {
          id: 3,
          name: 'notSpectified',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['shops', 'users']),
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    isPermission() {
      return permis.findPermissionRead('member', '/member/data')
    },
    isEdit() {
      return permis.findPermissionEdit('member', '/member/data')
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
  },
  
  methods: {
  getDateLocale() {
    if(this.$i18n.locale == 'en'){
      return { lang: 'en' }
      }else{
        return { lang: 'de' }
      }
      },
  
    validate() {
      if (!this.firstname || !this.lastname || !this.sex || !this.phone) {
        this.validateAlert = this.$i18n.t('validateInfomation')
      } else {
        this.validateAlert = ''
        if (!this.validatePhone(this.phone)) {
          this.errors = this.$i18n.t('validateTel')
        } else {
          this.errors = ''
          this.popupModal = true
        }
      }
    },
    saveMember() {
      this.loadingButton = true
      const uid = this.uid

      let data = {
        firstname: this.firstname,
        lastname: this.lastname,
        sex: this.sex,
        phone: this.phone,
        dateOfBirth: this.dateOfBirth,
        shopObjectId: this.shopObjectId,
        uid: uid,
        created_at: new Date(),
        updated_at: new Date(),
      }

      pos({
        method: 'post',
        url: '/api/v1.0/' + uid + '/Shop/updatemember/data',
        data: data,
      })
        .then((res) => {
          let objectId = res.data.data.objectId
          this.$router.push('/member/' + objectId + '/getdetail')
          this.loadingButton = false
          this.popupModal = false
          this.successAlert = this.$i18n.t('saveSuccess')
        })
        .catch((error) => {
          console.log(error)
        })
    },
    validatePhone(phone) {
      const MOBILEREG = /^[0]\d{9}$/
      return MOBILEREG.test(phone)
    },
  },
  
  
}
</script>
<style>
.datepicker-container {
  border: 1px solid #d8dbe0;
    color: #768192;
    border-radius: 0.25rem;
    height: calc(1.5em + 0.75rem + 2px);
}

.vd-picker__input input {
  margin-top: 0.1%;
  font-size: 14px !important;
  color: #768192;
}


</style>
